/* General container and layout styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  min-height: calc(100vh - 64px);
  background-color: rgba(225, 225, 225, 1);
}

.disabledTextarea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.authPrompt {
  text-align: center;
}

.authButtons {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.authButton {
  text-decoration: none;
  min-width: 120px;
  margin-right: 5px;
  gap: 15px;
  background: #6366f1;
  color: white;
  border: none;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.authButton:hover:not(:disabled) {
  background: #4f46e5;
}

.authButton:hover {
  background-color: #555;
}

/* Hero section at the top of the page */
.heroSection {
  text-align: center;
  padding: 40px 20px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 16px;
  color: white;
  margin-bottom: 40px;
  box-shadow: -0.15em -0.15em 0.15em -0.075em rgba(5, 5, 5, 0.25),
    0.0375em 0.0375em 0.0675em 0 rgba(5, 5, 5, 0.1);
}

.heroIcon {
  margin-bottom: 20px;
  animation: float 3s ease-in-out infinite;
}

/* Floating animation for the hero icon */
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.title {
  font-size: 2.5rem;
  margin-bottom: 16px;
  font-weight: 700;
}

.subtitle {
  font-size: 1.2rem;
  opacity: 0.9;
  padding: 0 20px;
}

/* Main content area */
.mainContent {
  gap: 30px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: stretch; /* Ensure equal height */
  width: 100%; /* Ensure full width */
}


.cardContainer {
  width: 100%; /* Ensure it takes full width within its container */
  max-width: 100%; /* Prevent it from becoming too wide */
  margin: 0 auto;
}

/* Card styles */
.card {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: -0.15em -0.15em 0.15em -0.075em rgba(5, 5, 5, 0.1),
    0.0375em 0.0375em 0.0675em 0 rgba(5, 5, 5, 0.05);
  overflow: hidden;
  margin-bottom: 20px;
}

.cardHeader {
  padding: 20px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.languageSelector {
  display: flex;
  gap: 12px;
  flex: 1;
  min-width: 250px;
}

.select {
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 0.95rem;
  outline: none;
  transition: all 0.3s ease;
  flex: 1;
}

.select:hover {
  border-color: #6366f1;
}

.select:focus {
  border-color: #6366f1;
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
}

.characterLimit {
  font-size: 0.9rem;
  color: #6b7280;
  white-space: nowrap;
}

.textareaContainer {
  padding: 20px;
  padding-right: 50px;
}

.textarea {
  width: 100%;
  min-height: 200px;
  padding: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 1rem;
  line-height: 1.5;
  resize: vertical;
  outline: none;
  transition: all 0.3s ease;
  font-family: inherit;
}

.textarea:focus {
  border-color: #6366f1;
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.2);
}

.cardFooter {
  padding: 20px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
}

/* Button base styles */
.convertButton, .downloadButton, .authButton {
  all: unset;
  cursor: pointer;
  position: relative;
  border-radius: 999vw;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: -0.15em -0.15em 0.15em -0.075em rgba(5, 5, 5, 0.25),
    0.0375em 0.0375em 0.0675em 0 rgba(5, 5, 5, 0.1);
}

.convertButton::after, .downloadButton::after, .authButton::after {
  content: "";
  position: absolute;
  z-index: 0;
  width: calc(100% + 0.3em);
  height: calc(100% + 0.3em);
  top: -0.15em;
  left: -0.15em;
  border-radius: inherit;
  background: linear-gradient(-135deg,
      rgba(5, 5, 5, 0.5),
      transparent 20%,
      transparent 100%);
  filter: blur(0.0125em);
  opacity: 0.25;
  mix-blend-mode: multiply;
}

.button-outer {
  position: relative;
  z-index: 1;
  border-radius: inherit;
  transition: box-shadow 300ms ease;
  will-change: box-shadow;
  box-shadow: 0 0.05em 0.05em -0.01em rgba(5, 5, 5, 1),
    0 0.01em 0.01em -0.01em rgba(5, 5, 5, 0.5),
    0.15em 0.3em 0.1em -0.01em rgba(5, 5, 5, 0.25);
}

.button-inner {
  position: relative;
  z-index: 1;
  border-radius: inherit;
  padding: 0.5em 1em;
  background-image: linear-gradient(135deg,
      rgba(230, 230, 230, 1),
      rgba(180, 180, 180, 1));
  transition: box-shadow 300ms ease, clip-path 250ms ease,
    background-image 250ms ease, transform 250ms ease;
  will-change: box-shadow, clip-path, background-image, transform;
  overflow: clip;
  clip-path: inset(0 0 0 0 round 999vw);
  box-shadow: 0 0 0 0 inset rgba(5, 5, 5, 0.1),
    -0.05em -0.05em 0.05em 0 inset rgba(5, 5, 5, 0.25),
    0 0 0 0 inset rgba(5, 5, 5, 0.1),
    0 0 0.05em 0.2em inset rgba(255, 255, 255, 0.25),
    0.025em 0.05em 0.1em 0 inset rgba(255, 255, 255, 1),
    0.12em 0.12em 0.12em inset rgba(255, 255, 255, 0.25),
    -0.075em -0.25em 0.25em 0.1em inset rgba(5, 5, 5, 0.25);
}

.button-inner span {
  position: relative;
  z-index: 4;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.05em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(135deg,
      rgba(25, 25, 25, 1),
      rgba(75, 75, 75, 1));
  -webkit-background-clip: text;
  background-clip: text;
  transition: transform 250ms ease;
  display: block;
  will-change: transform;
  text-shadow: rgba(0, 0, 0, 0.1) 0 0 0.1em;
  user-select: none;
}

/* Active (click) states */
.convertButton:active .button-outer,
.downloadButton:active .button-outer,
.authButton:active .button-outer {
  box-shadow: 0 0 0 0 rgba(5, 5, 5, 1), 0 0 0 0 rgba(5, 5, 5, 0.5),
    0 0 0 0 rgba(5, 5, 5, 0.25);
}

.convertButton:active .button-inner,
.downloadButton:active .button-inner,
.authButton:active .button-inner {
  clip-path: inset(clamp(1px, 0.0625em, 2px) round 999vw);
  box-shadow: 0.1em 0.15em 0.05em 0 inset rgba(5, 5, 5, 0.75),
    -0.025em -0.03em 0.05em 0.025em inset rgba(5, 5, 5, 0.5),
    0.25em 0.25em 0.2em 0 inset rgba(5, 5, 5, 0.5),
    0 0 0.05em 0.5em inset rgba(255, 255, 255, 0.15),
    0 0 0 0 inset rgba(255, 255, 255, 1),
    0.12em 0.12em 0.12em inset rgba(255, 255, 255, 0.25),
    -0.075em -0.12em 0.2em 0.1em inset rgba(5, 5, 5, 0.25);
  transform: scale(0.975);
}

.convertButton:active .button-inner span,
.downloadButton:active .button-inner span,
.authButton:active .button-inner span {
  transform: scale(0.975);
}

/* Disabled states */
.convertButton:disabled,
.downloadButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Prevent animation on disabled buttons */
.convertButton:disabled .button-inner,
.downloadButton:disabled .button-inner,
.convertButton:disabled .button-outer,
.downloadButton:disabled .button-outer {
  transform: none !important;
  box-shadow: none !important;
}

.loadingSpinner {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Audio section */
.audioCard {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.audioPlayer {
  width: 100%;
  margin-bottom: 16px;
}

.downloadButton {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  max-width: 200px;
  transition: background-color 0.3s;
}

.downloadButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.downloadButton:hover:not(:disabled) {
  background-color: #45a049;
}

.downloadButtons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.downloadButtons button {
  all: unset;
  cursor: pointer;
  position: relative;
  border-radius: 999vw;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: -0.15em -0.15em 0.15em -0.075em rgba(5, 5, 5, 0.25),
    0.0375em 0.0375em 0.0675em 0 rgba(5, 5, 5, 0.1);
  min-width: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
}

.downloadButtons button::after {
  content: "";
  position: absolute;
  z-index: 0;
  width: calc(100% + 0.3em);
  height: calc(100% + 0.3em);
  top: -0.15em;
  left: -0.15em;
  border-radius: inherit;
  background: linear-gradient(-135deg,
      rgba(5, 5, 5, 0.5),
      transparent 20%,
      transparent 100%);
  filter: blur(0.0125em);
  opacity: 0.25;
  mix-blend-mode: multiply;
}

.downloadButtons .button-outer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.downloadButtons .button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.downloadButtons .button-inner span {
  text-align: center;
  width: 100%;
}

.downloadButtons button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  box-shadow: none;
}

.downloadButtons button:active:not(:disabled) {
  transform: translateY(0);
}

@media (max-width: 640px) {
  .downloadButtons {
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
    padding: 0 1rem;
  }

  .downloadButtons button {
    width: 100%;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto;
  }
}

.errorMessage {
  background: #fee2e2;
  border: 1px solid #ef4444;
  color: #b91c1c;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 20px;
}

/* Ad section */
.adSection {
  width: 300px;
}

/* Features section */
.featuresSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
}

.featureCard {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.featureCard h3 {
  color: #1f2937;
  margin-bottom: 12px;
}

.featureCard p {
  color: #6b7280;
}

/* Responsive styles for mobile */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .mainContent {
    flex-direction: column;
  }

  .adSection {
    width: 100%;
  }

  .heroSection {
    padding: 30px 15px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .cardHeader {
    flex-direction: column;
    align-items: stretch;
  }

  .languageSelector {
    width: 100%;
    flex-direction: column;
  }

  .select {
    width: 100%;
  }

  .characterLimit {
    text-align: right;
  }

  .textarea {
    min-height: 150px;
  }

  .featuresSection {
    gap: 15px;
  }

  .featureCard {
    padding: 20px;
  }
}

.formatSelector {
  min-width: 100px;
  margin-left: 12px;
}

.controls {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
