/* Global box-sizing reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Main navbar styling */
.navbar {
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  margin-bottom: 20px;
}

/* Container to center and space content */
.navbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  padding: 0 20px;
  height: 64px;
  margin: 0 auto;
}

/* Logo styling */
.logo {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Desktop nav list */
.navList {
  display: flex;
  align-items: center;
  list-style: none;
  transition: transform 0.3s ease-in-out;
}

/* Navigation link styles (for both links and buttons) */
.navLink,
.navLinkSignUp,
.navLinkAI,
.navLinkAdmin {
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  white-space: nowrap;
}

.navLink:hover,
.navLinkSignUp:hover,
.navLinkAI:hover,
.navLinkAdmin:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Specific styling for AI and Admin links */
.navLinkAI {
  background: linear-gradient(135deg, #1a237e 0%, #0d47a1 100%);
  display: flex;
  align-items: center;
  gap: 8px;
}

.navLinkAdmin {
  background: linear-gradient(135deg, #c62828 0%, #d32f2f 100%);
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Mobile menu button hidden on desktop */
.mobileMenuButton {
  display: none;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

/* Adjust logout button styling when rendered as a button */
button.navLinkSignUp {
  background: none;
  border: none;
  padding: 8px 16px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.navLinkSignUp:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Mobile styles */
@media (max-width: 768px) {
  .mobileMenuButton {
    display: block;
  }
  
  /* Mobile nav list as a vertical sliding menu */
  .navList {
    position: fixed;
    top: 64px;
    right: 0;
    width: 240px;
    height: calc(100vh - 64px);
    background-color: #333;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 12px;
    transform: translateX(100%);
  }
  
  /* When mobile menu is active, slide in */
  .mobileActive {
    transform: translateX(0);
  }
  
  .navList li {
    width: 100%;
  }
  
  .navLink,
  .navLinkSignUp,
  .navLinkAI,
  .navLinkAdmin {
    padding: 12px 16px;
    font-size: 1rem;
    width: 100%;
  }
}

/* Update container styles in pages */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 40px;
  min-height: calc(100vh - 84px);
  background-color: rgba(225, 225, 225, 1);
}