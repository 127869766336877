.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  min-height: 100vh;
}

.heroSection {
  text-align: center;
  padding: 40px 20px;
  background: linear-gradient(135deg, #1a237e 0%, #0d47a1 100%);
  border-radius: 16px;
  color: white;
  margin-bottom: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.heroIcon {
  animation: float 3s ease-in-out infinite;
  margin-bottom: 20px;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 16px;
  font-weight: 700;
}

.subtitle {
  font-size: 1.2rem;
  opacity: 0.9;
}

.mainContent {
  background: white;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.inputGroup {
  margin-bottom: 24px;
}

.inputGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  resize: vertical;
}

.select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
}

.fileInput {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: white;
}

.submitButton {
  width: 100%;
  padding: 16px;
  background: #1a237e;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background: #0d47a1;
}

.submitButton:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.error {
  color: #d32f2f;
  padding: 12px;
  margin: 16px 0;
  background: #ffebee;
  border-radius: 8px;
}

.result {
  margin-top: 32px;
  padding: 24px;
  background: #f5f5f5;
  border-radius: 8px;
}

.audioPlayer {
  width: 100%;
  margin: 16px 0;
}

.downloadButton {
  display: inline-block;
  padding: 12px 24px;
  background: #1a237e;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.downloadButton:hover {
  background: #0d47a1;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

.loading {
  font-size: 1.2rem;
  color: #1a237e;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

@media (max-width: 768px) {
  .container {
    padding: 16px;
  }

  .title {
    font-size: 2rem;
  }

  .mainContent {
    padding: 24px;
  }
}

.voiceInputContainer {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.recordSection, .uploadSection {
  flex: 1;
}

.recordButton {
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #1a237e;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.recordButton.recording {
  background: #d32f2f;
  animation: pulse 1.5s infinite;
}

.uploadButton {
  width: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #0d47a1;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.uploadButton:hover, .recordButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fileInput {
  display: none;
}

.audioPreviewContainer {
  margin: 16px 0;
  padding: 16px;
  background: #f5f5f5;
  border-radius: 8px;
}

.templateSection {
  margin-top: 16px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  border-left: 4px solid #1a237e;
}

.template {
  margin: 8px 0 0;
  font-style: italic;
  color: #666;
}

.characterCount {
  display: block;
  text-align: right;
  color: #666;
  margin-top: 4px;
}

.authPrompt {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: #fff3e0;
  border-radius: 8px;
  margin-bottom: 24px;
  color: #e65100;
}

.authPrompt a {
  color: #1a237e;
  text-decoration: none;
  font-weight: 500;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.7; }
  100% { opacity: 1; }
}

.voiceSection {
  background: #f8f9fa;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 24px;
}

.voiceInstructions {
  color: #666;
  margin-bottom: 16px;
  font-size: 0.9rem;
}

.voiceControls {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.controlButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #1a237e;
  color: white;
  min-width: 140px;
  justify-content: center;
}

.controlButtonWrapper {
  display: flex;
  min-width: 140px;
}

.controlButton.clearButton {
  background: #666;
}

.previewSection {
  background: white;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.previewLabel {
  font-weight: 500;
  margin-bottom: 8px;
  color: #1a237e;
}

.templateSection {
  background: white;
  padding: 16px;
  border-radius: 8px;
  border-left: 4px solid #1a237e;
}

.templateLabel {
  font-weight: 500;
  margin-bottom: 8px;
  color: #1a237e;
}

.template {
  color: #666;
  font-style: italic;
  line-height: 1.5;
}

.audioPreview {
  width: 100%;
  margin-top: 8px;
}

.fileInput {
  display: none;
}

@media (max-width: 768px) {
  .voiceControls {
    flex-direction: column;
    gap: 16px;
  }
  
  .controlButton,
  .controlButtonWrapper {
    width: 100%;
  }

  .controlButton {
    padding: 16px 20px;
  }
} 