.container {
    padding: 24px 16px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  .title {
    margin-bottom: 32px;
    color: #1a237e;
    font-weight: 600;
    text-align: center;
    padding: 16px 0;
  }
  
  .statsContainer {
    display: flex;
    gap: 24px;
    margin-bottom: 32px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .statCard {
    padding: 24px;
    min-width: 240px;
    flex: 1;
    max-width: calc(50% - 12px);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    background: linear-gradient(135deg, #fff 0%, #f8f9fa 100%);
    border: 1px solid #e0e0e0;
  }
  
  .statCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  .tableContainer {
    overflow-x: auto;
    margin-top: 24px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin-bottom: 24px;
  }
  
  .table {
    min-width: 650px;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .table th {
    background-color: #f5f5f5;
    color: #1a237e;
    font-weight: 600;
    padding: 16px;
    text-align: left;
  }
  
  .table td {
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .table tr:hover {
    background-color: #f5f5f5;
  }
  
  .truncatedText {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .tabsContainer {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 16px 8px;
    }
  
    .statsContainer {
      flex-direction: column;
      gap: 16px;
    }
  
    .statCard {
      max-width: 100%;
      min-width: 100%;
    }
  
    .tableContainer {
      margin: 16px 8px;
      padding: 8px;
      border-radius: 8px;
    }
  
    .table th,
    .table td {
      padding: 12px 8px;
      font-size: 14px;
    }
  
    .truncatedText {
      max-width: 120px;
    }
  
    .title {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
  
  /* Custom scrollbar for better mobile experience */
  .tableContainer::-webkit-scrollbar {
    height: 6px;
  }
  
  .tableContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  
  .tableContainer::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  .tableContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
  }