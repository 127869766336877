.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    min-height: calc(100vh - 64px);
    font-family: Arial, sans-serif;
}

.title {
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
}

.content {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
    margin-bottom: 1.5rem;
}

.contactInfo {
    font-size: 1rem;
    color: #333;
    text-align: center;
    background-color: #f8f8f8;
    padding: 1rem;
    border-radius: 4px;
}
