.container {
  max-width: 400px;
  margin: 40px auto;
  padding: 32px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  box-shadow: -0.15em -0.15em 0.15em -0.075em rgba(5, 5, 5, 0.1),
    0.0375em 0.0375em 0.0675em 0 rgba(5, 5, 5, 0.05);
}

.title {
  text-align: center;
  color: #1a237e;
  font-weight: 600;
  margin-bottom: 32px;
  font-size: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input {
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.input:focus {
  outline: none;
  border-color: #1a237e;
  box-shadow: 0 0 0 3px rgba(26, 35, 126, 0.1);
}

.button {
  all: unset;
  cursor: pointer;
  position: relative;
  border-radius: 999vw;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: -0.15em -0.15em 0.15em -0.075em rgba(5, 5, 5, 0.25),
    0.0375em 0.0375em 0.0675em 0 rgba(5, 5, 5, 0.1);
  width: 100%;
  margin-top: 10px;
}

.button::after {
  content: "";
  position: absolute;
  z-index: 0;
  width: calc(100% + 0.3em);
  height: calc(100% + 0.3em);
  top: -0.15em;
  left: -0.15em;
  border-radius: inherit;
  background: linear-gradient(-135deg,
      rgba(5, 5, 5, 0.5),
      transparent 20%,
      transparent 100%);
  filter: blur(0.0125em);
  opacity: 0.25;
  mix-blend-mode: multiply;
}

.button-outer {
  position: relative;
  z-index: 1;
  border-radius: inherit;
  transition: box-shadow 300ms ease;
  will-change: box-shadow;
  box-shadow: 0 0.05em 0.05em -0.01em rgba(5, 5, 5, 1),
    0 0.01em 0.01em -0.01em rgba(5, 5, 5, 0.5),
    0.15em 0.3em 0.1em -0.01em rgba(5, 5, 5, 0.25);
}

.button-inner {
  position: relative;
  z-index: 1;
  border-radius: inherit;
  padding: 0.75em 1em;
  background-image: linear-gradient(135deg,
      rgba(230, 230, 230, 1),
      rgba(180, 180, 180, 1));
  transition: all 250ms ease;
  text-align: center;
}

.button-inner span {
  position: relative;
  z-index: 4;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.05em;
  font-weight: 500;
  color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(135deg,
      rgba(25, 25, 25, 1),
      rgba(75, 75, 75, 1));
  -webkit-background-clip: text;
  background-clip: text;
  text-shadow: rgba(0, 0, 0, 0.1) 0 0 0.1em;
}

.button:active .button-outer {
  box-shadow: 0 0 0 0 rgba(5, 5, 5, 1);
}

.button:active .button-inner {
  transform: scale(0.975);
}

.error {
  color: #dc2626;
  text-align: center;
  margin-top: 16px;
  padding: 12px;
  background: rgba(220, 38, 38, 0.1);
  border-radius: 8px;
  font-size: 0.9rem;
}

.redirectInfo {
  text-align: center;
  color: #666;
  font-size: 0.9rem;
  margin-top: 16px;
  font-style: italic;
}

@media (max-width: 480px) {
  .container {
    margin: 20px;
    padding: 24px;
  }

  .title {
    font-size: 1.75rem;
  }
}