.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    min-height: calc(100vh - 64px);
    font-family: Arial, sans-serif;
}

.title {
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
}

.content {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
    margin-bottom: 1.5rem;
}

.subtitle {
    color: #333;
    margin-bottom: 1rem;
}

.featureList {
    list-style-type: disc;
    padding-left: 1.5rem;
    color: #555;
}

.featureList li {
    margin-bottom: 0.5rem;
}
